<template>
  <div>
    <b-row>
      <b-col>
        <FormulateInput
          v-if="stores != null && stores.length > 1"
          id="store-name"
          v-model="filters.storeId"
          type="vue-select"
          class="required"
          :label="$t('Loja')"
          :placeholder="$t('Selecione')"
          :options="storeOptions"
          validation="required"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        v-for="(beerTap, index) in beerTaps"
        :key="beerTap.tapNumber"
        md="6"
        lg="3"
      >
        <b-card-actions
          :img-src="barrelImage(beerTap) || noImage"
          img-alt="Card image cap"
          img-top
          no-actions
          :show-loading="fetching || saving"
          :title="`#${beerTap.tapNumber} - ${barrelName(beerTap)}`"
        >
          <h3>
            <b-badge
              href="#"
              :variant="beerTap.status === 'Active' ? 'success' : 'danger'"
            >
              {{ $t(beerTap.status === 'Active' ? 'Ativa' : 'Inativa') }}
            </b-badge>
          </h3>
          <vue-apex-charts
            type="radialBar"
            height="245"
            :options="goalOverviewRadialBar.chartOptions"
            :series="beerTap.series"
          />
          <b-row class="text-center mx-0">
            <b-col
              cols="6"
              class="border-top border-right d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Capacidade') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-50">
                {{ barrelCapacity(beerTap) | number(2) }} L
              </h3>
            </b-col>

            <b-col
              cols="6"
              class="border-top d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Restante') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-0">
                {{ (beerTap.remainingLiquid / 1000) | number(2) }} L
              </h3>
            </b-col>
          </b-row>
          <b-row class="text-center mx-0">
            <b-col
              cols="12"
              class="border-top d-flex align-items-between flex-column py-1"
            >
              <b-card-text class="text-muted mb-0">
                {{ $t('Preço por Litro') }}
              </b-card-text>
              <h3 class="font-weight-bolder mb-50">
                <span v-if="!localBeerTaps[index].editingPrice">
                  {{ beerTap.currentPrice | currency }}
                </span>
                <FormulateInput
                  v-else
                  :id="`price-tap-${beerTap.tapNumber}`"
                  :key="beerTap.tapNumber"
                  v-model="beerTap.currentPrice"
                  type="text-number"
                  filter="currency"
                  currency="R$"
                  :precision="2"
                  validation="^required|min:0.01"
                  :validation-messages="{
                    required: $t('O preço é obrigatório'),
                    min: $t('Valor inválido'),
                  }"
                />
                <b-icon
                  v-if="beerTap.barrelInstalled && !localBeerTaps[index].editingPrice"
                  icon="pencil"
                  variant="primary"
                  font-scale="1"
                  class="cursor-pointer"
                  :title="$t('Alterar preço')"
                  @click="localBeerTaps[index].editingPrice = true"
                />
                <div v-if="beerTap.barrelInstalled && localBeerTaps[index].editingPrice">
                  <e-button
                    icon="check"
                    variant="primary"
                    font-scale="1"
                    class="cursor-pointer"
                    :title="$t('Confirmar')"
                    @click="updatePrice(beerTap, index)"
                  />
                  <e-button
                    icon="x"
                    variant="danger"
                    font-scale="1"
                    class="cursor-pointer ml-1"
                    :title="$t('Cancelar')"
                    @click="cancelUpdatePrice(index)"
                  />
                </div>
              </h3>
            </b-col>
          </b-row>
          <div class="mt-1">
            <b-button
              variant="outline-primary"
              @click="addBarrel(beerTap)"
            >
              {{ $t('Trocar Barril') }}
            </b-button>
            <b-button
              v-if="beerTap.status === 'Active'"
              variant="outline-danger"
              class="ml-1"
              @click="activateOrInactivateBeerTap(beerTap, false)"
            >
              {{ $t('Inativar') }}
            </b-button>
            <b-button
              v-else
              variant="primary"
              class="ml-1"
              @click="activateOrInactivateBeerTap(beerTap, true)"
            >
              {{ $t('Ativar') }}
            </b-button>
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
    <modal-barrel-search
      ref="modalBarrelSearch"
      :beer-tap="selectedBeerTap"
      @add-barrel="onAddItem"
    />
  </div>
</template>

<script>
import { BCardText, BButton, BRow, BCol, BBadge } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { mapActions, mapGetters, mapState } from 'vuex'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { EButton } from '@/views/components'
import ModalBarrelSearch from './components/ModalBarrelSearch.vue'

export default {
  components: {
    BCardActions,
    BCardText,
    BButton,
    BRow,
    BCol,
    BBadge,
    VueApexCharts,
    ModalBarrelSearch,
    EButton,
  },

  data() {
    return {
      showSidebar: false,
      fetching: false,
      edit: false,
      saving: false,
      selectedBeerTap: null,
      localBeerTaps: [
        { editingPrice: false },
        { editingPrice: false },
        { editingPrice: false },
        { editingPrice: false },
      ],
      // eslint-disable-next-line global-require
      noImage: require('@/assets/images/noimage.png'),
      goalOverviewRadialBar: {
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['green'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: ['green'],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
    }
  },

  computed: {
    ...mapState('pages/kiosk', ['stores']),
    ...mapState('pages/kiosk/beerTaps', ['beerTaps', 'filters']),
    // ...mapGetters('pages/kiosk/beerTaps', ['storeId']),
    ...mapGetters('pages/kiosk', ['storeOptions']),
    selectedStore() {
      return this.filters.storeId
    },
    barrelImage() {
      return beerTap => beerTap.barrelInstalled?.sku?.product?.imageSquare
    },
    barrelCapacity() {
      return beerTap => (beerTap.barrelInstalled?.capacity || 0) / 1000
    },
    barrelName() {
      return beerTap =>
        beerTap.barrelInstalled?.sku?.name
          ? `${beerTap.barrelInstalled?.sku?.name} (${this.$t('Barril')} #${
              beerTap.barrelInstalled.palmBeerBarrelCode
            })`
          : this.$t('Não instalado')
    },
  },

  watch: {
    selectedStore(val) {
      this.getData(val)
    },
    // stores(val) {
    //   if (val) {
    //     this.getData()
    //   }
    // },
  },

  async mounted() {
    await this.fetchStores()
    if (this.stores.length === 1) {
      this.setStoreFilter(this.stores[0].id)
    }
  },

  methods: {
    ...mapActions('pages/kiosk/beerTaps', ['fetchBeerTaps', 'setStoreFilter']),
    ...mapActions('pages/kiosk', ['fetchStores']),

    async getData(storeId) {
      this.$nextTick(async () => {
        try {
          this.fetching = true
          await this.fetchBeerTaps(storeId)
        } catch (error) {
          this.showError({ error })
        } finally {
          this.fetching = false
        }
      })
    },

    async activateOrInactivateBeerTap(beerTap, activate) {
      try {
        if (
          await this.confirm({
            text: this.$t('KIOSK.BEER_TAP.CONFIRM_BARREL_CHANGE_STATUS', [
              activate ? this.$t('ativação') : this.$t('inativação'),
              beerTap.tapNumber,
            ]),
          })
        ) {
          this.saving = true
          await this.$http({
            url: `/api/palmbeer/stores/${this.selectedStore}/beer-taps/${beerTap.tapNumber}/${
              activate ? 'activate' : 'inactivate'
            }`,
            method: 'PUT',
          })
          await this.getData()
          this.showSuccess({
            message: this.$t(`${activate ? 'Ativado' : 'Inativado'} com sucesso`),
          })
          this.selectedBeerTap = null
        }
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
    addBarrel(beerTap) {
      this.selectedBeerTap = beerTap
      this.$refs.modalBarrelSearch.showModal(beerTap)
    },
    async onAddItem(data) {
      try {
        this.saving = true
        await this.$http({
          url: `/api/palmbeer/stores/${this.selectedStore}/beer-taps/${this.selectedBeerTap.tapNumber}`,
          method: 'PUT',
          data: {
            barrelInstalledId: data.barrel.id,
          },
        })
        await this.getData()
        this.selectedBeerTap = null
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
    },
    async updatePrice(beerTap, index) {
      try {
        this.saving = true
        await this.$http({
          url: `/api/palmbeer/stores/${this.selectedStore}/beer-taps/${beerTap.tapNumber}/update-price`,
          method: 'PUT',
          data: {
            price: beerTap.currentPrice,
          },
        })
        this.showSuccess({
          message: this.$t(`Preço alterado com sucesso`),
        })
        this.localBeerTaps[index].editingPrice = false
      } catch (error) {
        this.showError({ error })
      } finally {
        this.saving = false
      }
      this.localBeerTaps[index].editingPrice = false
    },
    cancelUpdatePrice(index) {
      this.localBeerTaps[index].editingPrice = false
      this.getData()
    },
  },
}
</script>

<style>
img.card-img-top {
  height: 300px;
}
</style>
