var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-barrel-search",
        title: _vm.$t("Instalar Barril na Torneira") + " #" + _vm.tapNumber,
        "no-close-on-backdrop": "",
        "hide-footer": "",
        size: "lg",
        scrollable: "",
        centered: "",
      },
      on: { hidden: _vm.resetModal },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "b-container",
        [
          _c(
            "e-filters",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false",
                },
              ],
              attrs: { searching: _vm.fetching },
              on: { search: _vm.filterData, reset: _vm.resetFiltersLocal },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "6" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "modalAdvProduct-brand",
                          type: "vue-select",
                          label: _vm.$t("Marca"),
                          placeholder: _vm.$t("Todas"),
                          options: _vm.getComboBrands,
                        },
                        model: {
                          value: _vm.filters.brandId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "brandId", $$v)
                          },
                          expression: "filters.brandId",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-card-actions",
            {
              attrs: {
                title: _vm.$t("Barris disponíveis"),
                "no-actions": _vm.barrels.length === 0,
                "action-refresh": _vm.barrels.length > 0,
                "show-loading": _vm.fetching,
              },
              on: { refresh: _vm.filterData },
            },
            [
              _c(
                "div",
                { staticClass: "pb-1" },
                [
                  _c("e-page-size-selector", {
                    attrs: { "per-page": _vm.paging.pageSize },
                    on: { change: _vm.pageSizeChange },
                  }),
                ],
                1
              ),
              _c("b-table", {
                staticClass: "bordered",
                attrs: {
                  "show-empty": "",
                  responsive: "",
                  striped: "",
                  "empty-text": _vm.getEmptyTableMessage(
                    _vm.$tc("KIOSK.BARREL.NAME")
                  ),
                  fields: _vm.fields,
                  items: _vm.barrels,
                },
                scopedSlots: _vm._u([
                  {
                    key: "cell(imageSquare)",
                    fn: function (row) {
                      return [
                        _c("b-img", {
                          style: {
                            height: "auto",
                            width: "150px",
                            objectFit: "contain",
                          },
                          attrs: {
                            thumbnail: "",
                            src:
                              row.item.sku.product.imageSquare || _vm.noImage,
                            alt:
                              "Imagem " + (row.item.sku.name || "sem imagem"),
                          },
                        }),
                      ]
                    },
                  },
                  {
                    key: "cell(action)",
                    fn: function (row) {
                      return [
                        _c("e-button", {
                          attrs: {
                            variant: "primary",
                            icon: "plus",
                            title: _vm.$t("Instalar Barril na torneira"),
                          },
                          on: {
                            click: function ($event) {
                              return _vm.addBarrel(row.item)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("e-pagination-summary", {
                        attrs: {
                          "current-page": _vm.paging.currentPage,
                          "per-page": _vm.paging.pageSize,
                          total: _vm.paging.rowCount,
                          "total-on-page": _vm.paging.rowsInCurrentPage,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("b-pagination", {
                        attrs: {
                          align: "right",
                          "total-rows": _vm.paging.rowCount,
                          "per-page": _vm.paging.pageSize,
                          "aria-controls": "client-table",
                        },
                        on: { change: _vm.pageChange },
                        model: {
                          value: _vm.paging.currentPage,
                          callback: function ($$v) {
                            _vm.$set(_vm.paging, "currentPage", $$v)
                          },
                          expression: "paging.currentPage",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }